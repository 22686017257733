<template>
  <div class="admin-review">
    <div class="block">
      <span class="demonstration">设置报名时间</span>
      <el-date-picker
        v-model="value"
        @change="dateChange"
        format="yyyy-MM-dd HH:mm"
        value-format="yyyy-MM-dd HH:mm"
        type="datetimerange"
        start-placeholder="报名开始时间"
        end-placeholder="报名截止时间"
        :default-time="['12:00:00']">
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import { setMatchTime, getMatchTime } from '@/api'
export default {
  data () {
    return {
      value: '',
      matchTime: {
        startTime: '', // 比赛开始时间
        endTime: '' // 比赛截止时间
      }
    };
  },
  created () {
    this.getMatchTime()
  },
  methods: {
    dateChange (val) {
      this.matchTime.startTime = val[0];
      this.matchTime.endTime = val[1];
      setMatchTime(this.matchTime).then(res => {
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: '设置成功！'
          })
        } else {
          this.$message({
            type: 'success',
            message: '设置失败！'
          })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    getMatchTime () {
      this.loading = true
      getMatchTime().then(res => {
        this.loading = false
        this.value = res.data
      }).catch(err => {
        this.loading = false
        console.error(err)
      })
    }
  }
};

</script>
<style scoped lang="scss">
  .admin-review {
    padding: 30px;
  }
  .block {
    margin: 0 auto;
    text-align: center;
  }
  .demonstration{
    padding: 0 10px;
  }
</style>
